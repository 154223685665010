<template>
  <div class="preview-container">
    <iframe v-if="redirectUrl" :src="redirectUrl" frameborder="0" style="width:100%;height:calc(100% - 32px)"></iframe>
    <img v-else src="@/assets/fd-loading.png" class="loading-img" loading="lazy"/>
    <p style="font-size:12px;" class="margin_top10">加载中...</p>
  </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
  data() {
    return {
      redirectUrl: "",
    };
  },
  computed: {
    ...mapState({
      is_web: state => state.app.isWebSide,
    }),
  },
  created() {
    // electron端
    if(!this.is_web) {
      this.redirectUrl = decodeURIComponent(this.$route.query.url);
    }else if( this.$route.query.url ){
      // web 
      window.location.href = decodeURIComponent(this.$route.query.url);
    }
  },
  methods: {
    refresh() {
      window.addEventListener('message', function(e){
        if(e.data && e.data.course_id && e.data.url) {
          // 预览成功
          window.location.href = e.data.url;
        }else if(e.data && e.data.course_id && !!e.data.error) {
          // 预览出错
          // window.opener.location.reload();
          window.close();
        }
      });
    },
  }
}
</script>

<style lang="less" scoped>
  .preview-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100%;
  }
  .loading-img {
    // width: 140px;
    // height: 140px;
    width: 60px;
    height: 60px;
    object-fit: contain;
  }
</style>